var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Marketing_wrapper" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.currView,
            callback: function($$v) {
              _vm.currView = $$v
            },
            expression: "currView"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "秒杀设置", name: "SecondsKill" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "集赞设置", name: "GatherPraise" }
          }),
          _c("el-tab-pane", { attrs: { label: "卡券设置", name: "Coupon" } }),
          _c("el-tab-pane", {
            attrs: { label: "公众号引流设置", name: "VipcnDrainage" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "分享奖励设置", name: "ShareAward" }
          }),
          _c("el-tab-pane", { attrs: { label: "逛店设置", name: "InStore" } })
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _c(_vm.currView, {
            tag: "component",
            attrs: { activityID: _vm.activityID }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }