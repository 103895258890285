<template>
  <div class="Marketing_wrapper">
    <el-tabs v-model="currView">
      <el-tab-pane label="秒杀设置" name="SecondsKill"></el-tab-pane>
      <el-tab-pane label="集赞设置" name="GatherPraise"></el-tab-pane>
      <el-tab-pane label="卡券设置" name="Coupon"></el-tab-pane>
      <el-tab-pane label="公众号引流设置" name="VipcnDrainage"></el-tab-pane>
      <el-tab-pane label="分享奖励设置" name="ShareAward"></el-tab-pane>
      <el-tab-pane label="逛店设置" name="InStore"></el-tab-pane>
    </el-tabs>
    <keep-alive>
      <component :is="currView" :activityID="activityID"></component>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "Marketing", // 营销设置
  components: {
    SecondsKill: () => import("./MarketingChildren/SecondsKill"),
    GatherPraise: () => import("./MarketingChildren/GatherPraise"),
    Coupon: () => import("./MarketingChildren/Coupon"),
    VipcnDrainage: () => import("./MarketingChildren/VipcnDrainage"),
    ShareAward: () => import("./MarketingChildren/ShareAward"),
    InStore: () => import("./MarketingChildren/InStore")
  },
  props: {},
  data() {
    return {
      currView: "SecondsKill",
      activityID:0
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
    console.log(this.$route.params.activityID);
    this.activityID = this.$route.params.activityID;
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.Marketing_wrapper {
}
</style>
